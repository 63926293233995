import React, { useState } from 'react'
import { useModal } from '../../../contexts/Modal'
import { Button, Divider, Flex, Input, Modal, Radio, RadioChangeEvent } from 'antd'
import { IFulfill, IUserFulfill } from '../../../types/IFulfill'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrderAPI from '../../../api/OrderAPI'
import { notify } from '../../../contexts/Notification'
import { USER_FULL_FILL_SERVICE_QUERY_KEY } from '../queries/user-fulfill.query'

function UpdateFulfillServiceModal({ service }: { service: IFulfill & { userData?: IUserFulfill } }) {
    const { closeModal } = useModal()
    const [moreInfoData, setMoreInfoData] = useState<Record<string, string>>(() =>
        service.required_data
            .filter((d) => d !== 'token')
            .reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur]: service.userData?.more_info?.[cur] ?? ''
                }),
                {}
            )
    )

    console.log('initFormModel', moreInfoData)
    const [authType, setAuthType] = useState('ACCESS_TOKEN_')
    const [token, setToken] = useState('')
    const queryClient = useQueryClient()
    const mutate = useMutation({
        mutationFn: OrderAPI.mutateUserFulfillService,
        onSuccess: () => {
            notify('success', 'Thành công')
            void queryClient.refetchQueries([USER_FULL_FILL_SERVICE_QUERY_KEY])
            closeModal()
        },
        onError: (err) => {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    })

    const onChange = (e: RadioChangeEvent) => {
        setAuthType(e.target.value)
    }

    const moreInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMoreInfoData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const onSubmit = async () => {
        const fullAuthToken = authType + token
        const data = {
            service: service._id,
            moreInfo: moreInfoData,
            ...(token && {
                token: fullAuthToken
            })
        }
        await mutate.mutateAsync({
            sId: service.userData?._id,
            data
        })
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open
            onCancel={() => closeModal()}
            title={
                <Flex gap={12}>
                    <img src={service.icon} width={40} height={40} alt='service icon' className='flex-shrink-1' />
                    <div>
                        <p className='text-uppercase font-weight-bolder'>{service.service}</p>
                        <p style={{ fontSize: 12, fontWeight: 400 }}>{service.description}</p>
                    </div>
                </Flex>
            }
            footer={null}
            width={800}
        >
            <Divider />
            <p className='font-weight-bold mb-2'>Loại xác thực</p>
            <Radio.Group onChange={onChange} value={authType}>
                <Radio value='ACCESS_TOKEN_'>Access token</Radio>
                <Radio value='API_KEY_'>API Key</Radio>
            </Radio.Group>
            <p className='font-weight-bold mt-3 mb-2'>Token mới</p>
            <Input placeholder='Input auth token' value={token} onChange={(e) => setToken(e.target.value)} />
            {Object.keys(moreInfoData).map((key) => (
                <div key={key}>
                    <p className='font-weight-bold mt-3 mb-2 text-uppercase'>{key}</p>
                    <Input placeholder={`Input ${key}`} value={moreInfoData[key]} name={key} onChange={moreInfoChange} />
                </div>
            ))}
            <Button type='primary' onClick={onSubmit} loading={mutate.isLoading} className='mt-4 w-100'>
                Xác nhận
            </Button>
        </Modal>
    )
}

export default UpdateFulfillServiceModal
