import React from 'react'
import { useModal } from '../../../../../contexts/Modal'
import { Button, Divider, Flex, Modal, Typography } from 'antd'
import { IPackage } from '../../../../../types/IPackage'
import { useAuth } from '../../../../../contexts/Authenticate'
import { CaretRightOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { formatCurrency, formatDate, USDvsVND } from '../../../../../utils/format'
import { IUser } from '../../../../../types/IUser'
import { useMutation } from '@tanstack/react-query'
import PackageAPI from '../../../../../api/PackageAPI'
import { notify } from '../../../../../contexts/Notification'
import DepositAmountModal from "../../Wallet/modals/DepositAmountModal";

const calculatePackage = (pkg: IPackage, duration: number, user: IUser) => {
    const userPackage = user.package
    const color = pkg.level > userPackage?.package_detail?.level ? 'text-success' : 'text-danger'

    const currentPackageRemainingDuration = dayjs.unix(userPackage.expire_at).diff(dayjs(), 'day')
    const currentPackageRemainingAmount =
        userPackage?.package_detail?.slug === 'free'
            ? 0
            : currentPackageRemainingDuration <= 0
              ? 0
              : Math.floor(
                    (((userPackage.price_per_shop ?? userPackage.package_detail.price_per_shop) * userPackage.shop_limit) / 31) *
                        currentPackageRemainingDuration
                )
    const priceNeedToPay = pkg.price_per_shop * pkg.shop_limit * duration - currentPackageRemainingAmount
    const newCredit = user.credit - priceNeedToPay

    return {
        color,
        currentPackageRemainingAmount,
        priceNeedToPay,
        newCredit
    }
}

function PackageUpgradeModal({ pkg, duration }: { pkg: IPackage; duration: number }) {
    const { closeModal, openModal } = useModal()
    const { user } = useAuth()
    const changePackageMutation = useMutation({
        mutationFn: PackageAPI.changePackage,
        onSuccess: () => {
            window.location.reload()
        },
        onError: (err) => {
            notify('error', (err as Error).message)
        }
    })
    const { color, currentPackageRemainingAmount, priceNeedToPay, newCredit } = calculatePackage(pkg, duration, user)

    const onConfirm = () =>
        changePackageMutation.mutateAsync({
            newPackageId: pkg._id,
            duration
        })

    const openDepositAmountModal = () => openModal(<DepositAmountModal amount={`${Math.abs(newCredit)}`} />)

    return (
        <Modal maskClosable={false} closable open onCancel={() => closeModal()} title='Xác nhận thông tin' footer={null} width={500}>
            <Flex justify='space-between'>
                <Typography>Tên gói:</Typography>
                <Typography className='font-weight-bold'>
                    <span style={{ color: '#777E90' }}>{user?.package?.package_detail?.name}</span> <CaretRightOutlined className={color} />{' '}
                    {pkg.name}
                </Typography>
            </Flex>
            <Flex justify='space-between'>
                <Typography>Số lượng shop:</Typography>
                <Typography className='font-weight-bold'>
                    <span style={{ color: '#777E90' }}>{user?.package?.shop_limit}</span> <CaretRightOutlined className={color} /> {pkg.shop_limit}
                </Typography>
            </Flex>
            <Flex justify='space-between'>
                <Typography>Thời hạn:</Typography>
                <Typography className='font-weight-bold'>
                    <span style={{ color: '#777E90' }}>{`${formatDate(dayjs.unix(user?.package?.expire_at))}`}</span>
                    <CaretRightOutlined className={color} /> {formatDate(dayjs().add(duration, 'month'))}
                </Typography>
            </Flex>
            <Divider />
            <Flex justify='space-between'>
                <Typography>Số tiền gốc (1)</Typography>
                <Typography className='font-weight-bold'>
                    {`${formatCurrency(pkg.price_per_shop * pkg.shop_limit * duration, {
                        prefix: '$',
                        rate: USDvsVND
                    })}`}
                </Typography>
            </Flex>
            <Flex justify='space-between'>
                <Typography>Số tiền quy đổi gói hiện tại (2)</Typography>
                <Typography className='font-weight-bold'>
                    {`${formatCurrency(currentPackageRemainingAmount, { prefix: '$', rate: USDvsVND })}`}
                </Typography>
            </Flex>
            <Flex justify='space-between'>
                <Typography>Số tiền cần thanh toán (3 = 1 - 2)</Typography>
                <Typography className='font-weight-bold'>{`${formatCurrency(priceNeedToPay, {
                    prefix: '$',
                    rate: USDvsVND
                })}`}</Typography>
            </Flex>
            <Flex justify='space-between'>
                <Typography>Số dư mới:</Typography>
                <Typography className='font-weight-bold'>
                    <span style={{ color: '#777E90' }}>
                        {formatCurrency(user?.credit, {
                            rate: USDvsVND,
                            prefix: '$'
                        })}
                    </span>
                    <CaretRightOutlined className={color} />{' '}
                    <span className={newCredit < 0 ? 'text-danger' : 'text-info'}>
                        {formatCurrency(newCredit, {
                            rate: USDvsVND,
                            prefix: '$'
                        })}
                    </span>
                </Typography>
            </Flex>
            {newCredit < 0 ? (
                <>
                    <Typography className='text-danger mt-2'>
                        Số dư hiện tại không đủ. Vui lòng nạp thêm <strong>{formatCurrency(newCredit * -1, { rate: USDvsVND, prefix: '$' })}</strong>{' '}
                        để tiếp tục
                    </Typography>
                    <Button type='primary' danger className='mt-2 w-100' onClick={openDepositAmountModal}>
                        Nạp tiền
                    </Button>
                </>
            ) : (
                <Button type='primary' className='mt-2 w-100' onClick={onConfirm} loading={changePackageMutation.isLoading}>
                    Xác nhận thanh toán
                </Button>
            )}
        </Modal>
    )
}

export default PackageUpgradeModal
